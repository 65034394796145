import React from 'react';

import ShowcasePage from '../../../components/ShowcasePage';

const Showcase = () => (
  <ShowcasePage
    totalItems={17}
    s3Path={`portfolio/smash-the-cake/2/`}
  />
)

export default Showcase;
